<template id="searchdetail">
<ons-splitter>
  <ons-splitter-side id="menu" side="left" width="220px" collapse swipeable>
     <v-ons-page>
       <div class="sidebar">
          <v-ons-icon size="40px" icon="user" style="color: #6EB0DF" ></v-ons-icon>
          <br><br>{{currentUser.displayName}} <br> Your last login was at {{lastLogin}}.
       </div>
       <ons-list>
        <ons-list-item tappable>
          <router-link to="/">Search</router-link> 
        </ons-list-item>
        <ons-list-item tappable>
          <router-link to="/logout">Logout</router-link> 
        </ons-list-item>
      </ons-list>
     </v-ons-page>
  </ons-splitter-side>
  <ons-splitter-content id="content" > 
    <v-ons-page>
      <ons-toolbar style="background: #57a188;">
          <div class="left">
            <ons-toolbar-button onclick="fn.open()">
              <ons-icon style="color: white;" icon="md-menu"></ons-icon>
            </ons-toolbar-button>
          </div>
          <div class="center">        
            <div class="companyicon">   
              <img src="../../public/img/icons/favicon-32x32.png"> Terratone
            </div>
          </div>
      </ons-toolbar>

      <div id="top"></div>
     

      <!-- input -->

      <div width="100%" id="inputtext">  
           <v-ons-input
            placeholder="Enter Keyword" modifier="underbar" @keyup="loadproperty" size="20px" maxlength="20" float v-model="instantsearchtext" style="margin-left:5px"></v-ons-input> 
         
            <v-ons-button style="background-color:#6EDFA8;margin-left:5px"   icon="times" size="20px" @click="clearkeyword()"></v-ons-button> 

             <v-ons-button style="background-color:#FFC333;margin-left:5px"   icon="adjust" size="20px" @click="togglehighlight"></v-ons-button>
        
      </div>
    
      <!-- filter control -->
      <div  class="filtercontrol">
        <v-ons-list> 
          <v-ons-list-item v-show="showRecord && !showProgress" expandable>
            <ons-icon style="color: #FF8733;" icon="filter"></ons-icon>
            <div class="expandable-content">
                <v-ons-select class="filters"
                v-model="selectedfiltercontactid">
                <option v-for="item in filtercontactid" :key="item.id" :value="item.value">
                  {{ item.text}}
                </option>
              </v-ons-select>
              <v-ons-select sclass="filters"
                v-model="selectedfiltermarkposi">
                <option v-for="item in filtermarkposi" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </v-ons-select>
              <br>
              <v-ons-select class="filters"
                v-model="selectedfilterrlts">
                <option v-for="item in filterrlts" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </v-ons-select>
              <v-ons-select class="filters"
                v-model="selectedfilterivstrng">
                <option v-for="item in filterivstrng" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </v-ons-select>
              <br>
            
              <v-ons-select class="filters"
                v-model="selectedfiltertype">
                <option v-for="item in filtertype" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </v-ons-select>
              <v-ons-select class="filters"
                v-model="selectedfilterlstrst">
                <option v-for="item in filterlstrst" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </v-ons-select>
              <br>
              <v-ons-select class="filters"
                v-model="selectedfilternxtact">
                <option v-for="item in filternxtact" :key="item.value" :value="item.value">
                  {{ item.text }}
                </option>
              </v-ons-select>

            </div>
            
          </v-ons-list-item>
        </v-ons-list>
      </div>

      <!-- filter message -->
      <div class="filtermsg"  v-show="showRecord && !showProgress">
        <div v-if="(propertylist.length > 50) && (filterPropertyList.length == 50)">
        {{ propertylist.length }} records found. Maximum 50 records can be shown. Please specific your keyword.
        </div>
        <div v-if="(propertylist.length <= 50) || (filterPropertyList.length < 50)">
        {{ filterPropertyList.length }} records found/filtered.
        </div>
      </div>

    <!--filterPropertyList -->
      <div class="fulldiv">
        <v-ons-list>
          <v-ons-list-item tappable v-for="(item, index) in filterPropertyList" :key="index">  
              <div class="center" v-show="showRecord && !showProgress"> 
                  <div id="contactregion" style="width:100%"  @click="showModal(item.id, item.database, index)"> 
                      
                      <div class="list-item__title contactname" v-show="(item.FirstName1)"><v-ons-icon size="15px" icon="laugh" style="color: blue;" class="list-item__icon" ></v-ons-icon>  <span v-html="highlightTitleCase(item.FirstName1 + ' ' + item.LastName1, instantsearchtext)"> </span>   </div>
                      <div class="list-item__title contactname" v-show="(item.FirstName2)"><v-ons-icon size="15px" icon="laugh" style="color: red;" class="list-item__icon" ></v-ons-icon> <span v-html="highlightTitleCase(item.FirstName2 + ' ' + item.LastName2, instantsearchtext)"> </span>    </div>
                      <div class="list-item__title contactname"  v-show="(item.Company)"><v-ons-icon size="15px" icon="building" class="list-item__icon" style="color: #57a188;" ></v-ons-icon> <span v-html="highlightTitleCase(item.Company, instantsearchtext)"> </span>  </div>
                      <div style="margin: 5px 30px">
                          <div class="list-item__title">Database: {{ item.database}} </div>
                          <div class="list-item__title" v-show="(item.Street1)">Street1: <span v-html="highlightTitleCase(item.Street1, instantsearchtext)"> </span>  </div>
                          <div class="list-item__title" v-show="(item.Street2)">Street2: <span v-html="highlightTitleCase(item.Street2, instantsearchtext)"> </span>  </div>
                          <div class="list-item__title" v-show="(item.Street3)">Street3: <span v-html="highlightTitleCase(item.Street3, instantsearchtext)"> </span>  </div>
                          <div class="list-item__title" v-show="(item.City)">City: <span v-html="highlightTitleCase(item.City, instantsearchtext)"> </span>  </div>
                          <div class="list-item__title" v-show="(item.Zip)">Postal: <span v-html="highlight(item.Zip, instantsearchtext)"> </span>  </div>
                          <div class="list-item__title" v-show="(item.Telephone1)">Telephone1: <span v-html="highlight(item.Telephone1, instantsearchtext)"></span>   </div>
                          <div class="list-item__title" v-show="(item.Telephone2)">Telephone2: <span v-html="highlight(item.Telephone2, instantsearchtext)"></span></div>
                          <div class="list-item__title" v-show="(item.Telephone3)">Telephone3: <span v-html="highlight(item.Telephone3, instantsearchtext)"></span></div>
                          <div class="list-item__title" v-show="(item.Telephone4)">Telephone4: <span v-html="highlight(item.Telephone4, instantsearchtext)"></span></div>
                          <div class="list-item__title" v-show="(item.Telephone5)">Telephone5: <span v-html="highlight(item.Telephone5, instantsearchtext)"></span></div>
                          <div class="list-item__title" v-show="(item.Email)">Email: {{ item.Email}}</div>
                          <div class="list-item__title" v-show="(item.ContactID)">ContactID: {{ item.ContactID}}</div>
                          <div class="list-item__title" v-show="(item.MarkPosi)">MarketPos: {{ item.MarkPosi}}</div>
                          <div class="list-item__title" v-show="(item.Rltnshp)">Relationship: {{ item.Rltnshp}}</div>
                          <div class="list-item__title" v-show="(item.IvstRng)">InvestRange: {{ item.IvstRng}}</div>
                          
                          <div class="list-item__title" v-show="(item.Type)">Type: {{ item.Type}}</div>
                          <div class="list-item__title" v-show="(item.LastResult)">LastResult: {{ item.LastResult}}</div>
                          <div class="list-item__title" v-show="(item.NextAction)">NextAction: {{ item.NextAction}}</div>            
                      </div>
                  </div>
              
                  <v-ons-list-item expandable>
                    <div class="propertyowned">
                    <span> Properties Owned ({{ item.PropertyList.length }}) </span>
                    </div>
                  <div id="propertyregion" class="expandable-content">
                    
                    <v-ons-list-item tappable v-for="(pitem, pindex) in item.PropertyList" :key="pindex"  >  
                       <div class="propertydetail" v-show="(pitem.PID)">
                         
                            <v-ons-icon size="15px" icon="map-marker" class="list-item__icon" ></v-ons-icon>
                            <span v-html="highlightTitleCase(pitem.LocationOriginal, instantsearchtext)"></span> {{ pitem.LocationCity}}  {{ pitem.LocationState }} 
                            <span v-if="pitem.LocationZip">, {{ pitem.LocationZip }} </span> ({{ pitem.PID}}) {{ pitem.MorePIDs }} 
                        </div>
                        <div>                     
                            <span style="margin-right:15px;"><a href="#" class="relink" @click="askforRelink($ons,item.id,item.database, pitem.taxrecord_id, pitem.LocationOriginal, pitem.PID, pitem.MorePIDs)" >Relink</a></span>
                            <span style="margin-right:15px;"><a href="#" class="relink" @click="askforUnlink($ons,item.id, item.database, pitem.taxrecord_id, pitem.LocationOriginal, pitem.LocationCity, pitem.LocationState, pitem.LocationZip, pitem.LocationCountry, pitem.PID, pitem.MorePIDs)">Unlink</a></span>
                            <span><a href="#" class="relink" @click="askforCopyto($ons,item.id, item.database, pitem.taxrecord_id, pitem.LocationOriginal, pitem.LocationCity, pitem.LocationZip, pitem.LocationState, pitem.LocationCountry, item.Street1, item.City, item.Zip)">Copy Address</a></span>
                        </div>                      
                     
                    </v-ons-list-item>
                    <v-ons-list-item>                   
                        <div  class="list-item__title"  style="top:5%;left:20%;">
                            <span style="margin-right:15px;"><a href="#" class="relink" @click="askforLink($ons, item.database, item.id)" >Add Property</a></span>                       
                        </div>                     
                    </v-ons-list-item>
                   
                 </div>
                </v-ons-list-item>
              </div>      
            </v-ons-list-item>             
          </v-ons-list>
      </div>

<!-- Carousell popup for edit contact -->       
    <v-ons-modal :visible=modalVisible>  
        <v-ons-toolbar style="background: #57a188;width:100%">
          <div class="left"><ons-toolbar-button @click="carouselIndex > 0 && carouselIndex--"><ons-icon style="color:white" icon="md-chevron-left"></ons-icon></ons-toolbar-button></div>
          <div class="center"> <font style="color:white">{{ items[carouselIndex] }} </font></div>
          <div class="right"><ons-toolbar-button @click="carouselIndex < 2 && carouselIndex++"><ons-icon style="color:white" icon="md-chevron-right"></ons-icon></ons-toolbar-button></div>
        </v-ons-toolbar>

        <v-ons-carousel style="overflow-y: auto;" fullscreen swipeable auto-scroll overscrollable :index.sync="carouselIndex">
          <!-- Contact -->
                <v-ons-carousel-item>
                  <div style="text-align: center; margin-top: 70px; color: #fff;"></div>
                  <v-ons-list >
                    
                    <v-ons-list-item class="editrow">
                      <div class="center" >
                        
                      </div>
                      <div class="right">         
                        <v-ons-icon icon="md-close" size="15px" class="list-item__icon" @click="savecontact($ons,editcontactinfo.id, editcontactinfo.database)"> </v-ons-icon>
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item>
                      <div class="center">
                        Database: {{ editcontactinfo.database}}
                      </div>
                      <div class="right">
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                      <div class="center" >
                        <v-ons-select class="editmr" placeholder="Title (1)" v-model="editcontactinfo.data.Title1">
                          <option value=""></option>
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="Mrs">Mrs</option>
                        </v-ons-select>

                        <v-ons-input class="editname" placeholder="First Name (1)" float v-model="editcontactinfo.data.FirstName1" /> 
                      
                        <v-ons-input class="editname"  placeholder="Last Name (1)" float v-model="editcontactinfo.data.LastName1"  />
                      </div> 
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                      <div class="center" >
                        <v-ons-select class="editmr" placeholder="Title (2)" v-model="editcontactinfo.data.Title2">
                          <option value=""></option>
                          <option value="Mr">Mr</option>
                          <option value="Ms">Ms</option>
                          <option value="Mrs">Mrs</option>
                        </v-ons-select>
                          <v-ons-input class="editname" placeholder="First Name (2)" float v-model="editcontactinfo.data.FirstName2" />  
                      
                        <v-ons-input class="editname" placeholder="Last Name (2)" float v-model="editcontactinfo.data.LastName2"/> </div>
                    </v-ons-list-item>
                  
                    <v-ons-list-item >
                      <div class="center" >
                        <v-ons-input class="editrow" placeholder="Company" float v-model="editcontactinfo.data.Company " /> 
                      </div>
                      
                    </v-ons-list-item>
                    <v-ons-list-item>
                      <div class="center" >
                        <v-ons-input class="editrow" placeholder="Email" float v-model="editcontactinfo.data.Email "/> 
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                        <v-ons-input  class="edithalfrow" placeholder="Telephone (1)" float v-model="editcontactinfo.data.Telephone1"/> <v-ons-input  class="edithalfrow" placeholder="Telephone (2)" float v-model="editcontactinfo.data.Telephone2"/> 
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                        <v-ons-input class="edithalfrow" placeholder="Telephone (3)" float v-model="editcontactinfo.data.Telephone3"/> <v-ons-input class="edithalfrow" placeholder="Telephone (4)" float v-model="editcontactinfo.data.Telephone4"/> 
                    </v-ons-list-item> 
                    <v-ons-list-item class="editrow">
                        <v-ons-input placeholder="Telephone (5)" float v-model="editcontactinfo.data.Telephone5"/> 
                    </v-ons-list-item>   
                  </v-ons-list>
                </v-ons-carousel-item>

          <!--Contact Address  -->
                <v-ons-carousel-item >
                <div style="text-align: center; margin-top: 70px; color: #fff;"></div>
                  <v-ons-list >    
                    <v-ons-list-item class="editrow">
                      <div class="center"></div>
                      <div class="right">
                        <v-ons-icon icon="md-close" size="15px" class="list-item__icon" @click="savecontact($ons,editcontactinfo.id, editcontactinfo.database)" > </v-ons-icon>
                        </div>
                    </v-ons-list-item>
                    
                    <v-ons-list-item>
                        <v-ons-input class="editrow" placeholder="Contact Address (1)" float v-model="editcontactaddress.data.Street1 "/>
                    </v-ons-list-item>
                    <v-ons-list-item>
                        <v-ons-input class="editrow" placeholder="Contact Address (2)" float v-model="editcontactaddress.data.Street2 "/>
                    </v-ons-list-item>
                    <v-ons-list-item>
                        <v-ons-input class="editrow" placeholder="Contact Address (3)" float v-model="editcontactaddress.data.Street3 "/>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                        <v-ons-input placeholder="City"  style="width:50%" float v-model="editcontactaddress.data.City"/> <v-ons-input placeholder="Postal Code" style="width:50%"  float v-model="editcontactaddress.data.Zip" /> 
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                        <v-ons-input placeholder="Province" style="width:50%"  float v-model="editcontactaddress.data.State"/> <v-ons-input placeholder="Country" style="width:50%"  float v-model="editcontactaddress.data.Country"/>
                    </v-ons-list-item>          
                  </v-ons-list>
                </v-ons-carousel-item>

          <!--Contact Add Info  -->
                <v-ons-carousel-item >
                <div style="text-align: center; margin-top: 70px; color: #fff;"></div>
                  <v-ons-list >
                    <v-ons-list-item class="editrow">
                      <div class="center">
                      </div>
                      <div class="right">
                        <v-ons-icon icon="md-close" size="15px" class="list-item__icon" @click="savecontact($ons,editcontactinfo.id, editcontactinfo.database)"> </v-ons-icon>
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                      <div class="fulldiv">
                        <v-ons-select placeholder="ContactID" style="width: 96%" v-model="selectedcontactid">
                          <option v-for="item in optioncontactid"  :key="item.id" :value="item.value"> {{ item.text }}</option>
                        </v-ons-select>
                      </div >
                      <div class="fulldiv" v-show="(selectedcontactidvisible)"> 
                        <v-ons-input class="editoption" v-model="selectedcontactid" placeholder="Enter ContactID" type="text" />
                        <v-ons-button @click="selectedcontactidvisible=false">OK</v-ons-button>
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                      <div class="fulldiv">
                        <v-ons-select placeholder="Market Position" class="editrow" v-model="selectedmarkposi">
                          <option v-for="item in optionmarkposi"  :key="item.id" :value="item.value"> {{ item.text}}</option>
                        </v-ons-select>
                      </div>
                      <div class="fulldiv" v-show="(selectedmarkposivisible)"> 
                        <v-ons-input class="editoption" v-model="selectedmarkposi" placeholder="Enter Market Position" type="text" />
                        <v-ons-button @click="selectedmarkposivisible=false">OK</v-ons-button>
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                      <div class="fulldiv">
                        <v-ons-select placeholder="Relationship" class="editrow" v-model="selectedrltn">
                          <option v-for="item in optionrltn"  :key="item.id" :value="item.value"> {{ item.text }}</option>
                        </v-ons-select>
                      </div>
                      <div class="fulldiv" v-show="(selectedrltnvisible)"> 
                        <v-ons-input class="editoption" v-model="selectedrltn" placeholder="Enter Relationship" type="text" />
                        <v-ons-button @click="selectedrltnvisible=false">OK</v-ons-button>
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                      <div class="fulldiv">
                        <v-ons-select placeholder="Investment Range" class="editrow" v-model="selectedivstrng">
                          <option v-for="item in optionivstrng"  :key="item.id" :value="item.value"> {{ item.text }}</option>
                        </v-ons-select>
                      </div>
                      <div class="fulldiv" v-show="(selectedivstrngvisible)"> 
                        <v-ons-input class="editoption" v-model="selectedivstrng" placeholder="Enter Investment Type" type="text" />
                        <v-ons-button @click="selectedivstrngvisible=false">OK</v-ons-button>
                      </div>
                    </v-ons-list-item> 
                    <v-ons-list-item class="editrow">
                      <div class="fulldiv">
                          <v-ons-select placeholder="Type" style="width: 96%" v-model="selectedtype">
                          <option v-for="item in optiontype"  :key="item.id" :value="item.value"> {{ item.text }}</option>
                        </v-ons-select>
                      </div>
                      <div class="fulldiv" v-show="(selectedtypevisible)"> 
                        <v-ons-input class="editoption" v-model="selectedtype" placeholder="Enter Type" type="text" />
                        <v-ons-button @click="selectedtypevisible=false">OK</v-ons-button>
                      </div>
                    </v-ons-list-item>
                    
                    <v-ons-list-item class="editrow">
                      <div class="fulldiv">
                        <v-ons-select placeholder="Last Result" style="width: 96%" v-model="selectedlstrst">
                          <option v-for="item in optionlstrst"  :key="item.id" :value="item.value"> {{ item.text }}</option>
                        </v-ons-select>
                      </div>
                      <div class="fulldiv" v-show="(selectedlstrstvisible)"> 
                        <v-ons-input class="editoption" v-model="selectedlstrst" placeholder="Enter Last Result" type="text" />
                        <v-ons-button @click="selectedlstrstvisible=false">OK</v-ons-button>
                      </div>
                    </v-ons-list-item>
                    <v-ons-list-item class="editrow">
                      <div class="fulldiv">
                        <v-ons-select placeholder="Next Action" style="width: 96%" v-model="selectednxtact">
                          <option v-for="item in optionnxtact"  :key="item.id" :value="item.value"> {{ item.text }}</option>
                        </v-ons-select>
                      </div>
                      <div class="fulldiv" v-show="(selectednxtactvisible)"> 
                        <v-ons-input class="editoption" v-model="selectednxtact" placeholder="Enter Next Action" type="text" />
                        <v-ons-button @click="selectednxtactvisible=false">OK</v-ons-button>
                      </div>
                    </v-ons-list-item>   
                  </v-ons-list>
                </v-ons-carousel-item>

        </v-ons-carousel>

        <div :style="dots">
          <span :index="dotIndex - 1" v-for="dotIndex in Object.keys(items).length" :key="dotIndex" style="cursor: pointer" @click="carouselIndex = dotIndex - 1">
            {{ carouselIndex === dotIndex - 1 ? '\u25CF' : '\u25CB' }}
          </span>
        </div>
    </v-ons-modal>

<!-- Toast for Error -->
     <v-ons-toast :visible.sync="errortoastVisible" animation="ascend">
            Error {{ this.error }} 
             <button @click="errortoastVisible = false">Close</button>
     </v-ons-toast>
<!-- Toast for Saved -->
     <v-ons-toast :visible.sync="savedtoastVisible" animation="ascend">
            Contact Saved! 
             <button @click="savedtoastVisible=false">OK</button>
     </v-ons-toast>
<!-- Toast for New Linked -->
     <v-ons-toast :visible.sync="newlinkedtoastVisible" animation="ascend">
            Property Added! 
            <button @click="newlinkedtoastVisible=false">OK</button>
            <button @click="undo('newlink')">Undo</button>
     </v-ons-toast>
<!-- Toast for ReLinked -->
      <v-ons-toast :visible.sync="relinkedtoastVisible" animation="ascend">
            Property Relinked! 
            <button @click="relinkedtoastVisible=false">OK</button>
            <button @click="undo('relink')">Undo</button>
      </v-ons-toast>
<!-- Toast for Unlinked -->
      <v-ons-toast :visible.sync="unlinkedtoastVisible" animation="ascend">
            Property Unlinked! 
            <button @click="unlinkedtoastVisible=false">OK</button>
            <button @click="undo('unlink')">Undo</button>
      </v-ons-toast>

<!-- Link Dialog for Relink -->
      <v-ons-dialog cancelable :visible.sync="linkdialogVisible">    
        <div style="display: block;height:80vh;" >
            <v-ons-list >
                  <v-ons-list-header>New Contact</v-ons-list-header>
                  <v-ons-list-item>
                      <div class="center">
                        Database: {{ this.newdatabase }}
                      </div>
                  </v-ons-list-item>
                  <v-ons-list-item>
                  <v-ons-input style="width:96%" placeholder="Enter Contact Keyword" @keyup="loadlinkcontact" float v-model="linkcontactkeyword"/> 
                  </v-ons-list-item>
 
                  <v-ons-list-header v-show="(this.linkcontactlist != null && this.linkcontactlist.length > 0)"> Select ONE Contact to link
                  </v-ons-list-header>
                  <v-ons-list-item tappable v-for="(citem, index) in this.linkcontactlist" :key="index">  
                    <!--div> {{ citem.id }} </div-->
                  <div class="list-item__title" v-show="(citem.FirstName1)"> {{ citem.FirstName1}} {{ citem.LastName1}}</div>
                  <div class="list-item__title" v-show="(citem.FirstName2)"> {{ citem.FirstName2}} {{ citem.LastName2}}</div>
                  <div class="list-item__title"  v-show="(citem.Company)"> {{ citem.Company}}</div>
                  <div class="list-item__title">
                    {{ citem.City }} {{ citem.State }} {{ citem.Zip }} {{ citem.Country }} </div>
                  <div><a href="#" @click="selectRelink($ons,citem.id, citem.database, citem.FirstName1, citem.Company, false)">Select</a></div> 
                  </v-ons-list-item>
               
            </v-ons-list>
           </div>
      </v-ons-dialog>
<!-- Link Dialog for Newlink -->
      <v-ons-dialog cancelable :visible.sync="newlinkdialogVisible" >
          <div style="display: block;height:80vh;" >
          <v-ons-list >
                  <v-ons-list-header>  New Property </v-ons-list-header>
                  <v-ons-list-item>
                      <div class="center">
                        Database: {{ this.newdatabase }}
                      </div>
                  </v-ons-list-item>
                  <v-ons-list-item>
                    <v-ons-input style="width:96%" placeholder="Input PID (11 characters)" float @keyup="loadlinkpropertybypid" v-model="linkpropertypid"/> 
                  </v-ons-list-item>
                 <v-ons-list-item> 
                  <v-ons-input style="width:96%" placeholder="Enter Property Keyword" @keyup="loadlinkpropertybyaddress" float v-model="linkpropertykeyword"/> 
                  </v-ons-list-item>
                  <v-ons-list-header v-show="(this.linkpropertylist != null && this.linkpropertylist.length > 0)"> Select ONE Property to link
                  </v-ons-list-header>
                  <v-ons-list-item tappable v-for="(litem, index) in this.linkpropertylist" :key="index">  
                  <div class="list-item__title"  v-show="(litem.LocationOriginal)"> {{ litem.LocationOriginal}} {{ litem.LocationCity }} {{ litem.LocationState }} {{ litem.LocationZip }} {{ litem.LocationCountry}}</div>
                  <div class="list-item__title">
                    {{ litem.PID }} {{ litem.MorePIDs }}  </div>
                  <div><a href="#" @click="selectNewlink($ons, litem.taxrecord_id, litem.LocationOriginal, litem.LocationCity,litem.LocationState, litem.LocationZip, litem.LocationCountry, litem.PID, litem.MorePIDs, litem.contact_id)">Select</a></div>
                </v-ons-list-item>

            </v-ons-list>    
         </div>          
      </v-ons-dialog>
     
<!-- progress bar -->
      <div  v-show="showProgress" style="margin: 40%">
        <v-ons-progress-circular indeterminate ></v-ons-progress-circular>
      </div>

<!-- Add contact modal from adddetail.vue -->
      <div>     
          <addcontact ref="addmodal"></addcontact>
      </div>
 


<!-- fab for add contact-->
      <button class="fabadd" @click="openModal"><v-ons-icon icon="plus" size="30px" style="color: white" /></button>

<!-- fab for go top -->
      <button class="fabtop" v-show="showRecord && !showProgress" onClick="location.href='#top'"><v-ons-icon size="30px" icon="angle-up" /></button>
   
    
   </v-ons-page>
  </ons-splitter-content>
</ons-splitter>
</template>


<script>
window.fn = {};

window.fn.open = function() {
  var menu = document.getElementById('menu');
  menu.open();
};

window.fn.load = function(page) {
  var content = document.getElementById('content');
  var menu = document.getElementById('menu');
  content.load(page)
    .then(menu.close.bind(menu));
};

import router from "../router";
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import fb from "../firebase";
 
Vue.use(VueAxios, axios)

import addcontact from "../views/AddDetail.vue";

export default {
  components: {
     addcontact
  }, 
  data: function() {
    return {
 
      //propertyitems: [],
      router:router,
      
      currentUser:{},
      lastLogin: null,

      savedtoastVisible: false,
      relinkedtoastVisible: false,
      unlinkedtoastVisible: false,
      newlinkedtoastVisible: false,
      errortoastVisible: false,

      linkdialogVisible: false,
      newlinkdialogVisible: false,
      
      showProgress: false,
      showRecord: false,
      showAdd: false,
      searchtext: null,
      searchcriteria: null,
      instantsearchtext : null,
      state: 'initial',
      modalVisible: false,
      toggleHL: false,
      linkcontactkeyword: null,
      linkcontactlist: null,
      linkpropertykeyword: null,
      linkpropertypid: null,
      linkpropertylist: null,

      newdatabase: null,
      newcontactid: null,
      newtaxrecordsid: null,
      olddatabase: null,
      oldcontactid: null,
      oldtaxrecordsid: null,
      relinkLocationOriginal: null,
      relinkLocationCity: null,
      relinkLocationState: null,
      relinkLocationZip: null,
      relinkLocationCountry: null,
      relinkPID: null,
      relinkMorePIDs: null,

      error: null,
      propertylist:[],
      
      selectedfiltercontactid : null,
      selectedfiltertype : null,
      selectedfilterrlts : null,
      selectedfiltermarkposi : null,
      selectedfilterivstrng : null,
      selectedfilterlstrst: null,
      selectedfilternxtact: null,
      // Add more filter options - contact
      filtercontactid:[
        {text:'Developer', value:'Developer'},
        {text:'Foreign', value:'Foreign'},
        {text:'Foreign (HK)', value:'Foreign (HK)'},
        {text:'Foreign (CN)', value:'Foreign (CN)'},
        {text:'Foreign (KO)', value:'Foreign (KO)'},
        {text:'Foreign (SG)', value:'Foreign (SG)'},
        {text:'Foreign (TW)', value:'Foreign (TW)'}, 
        {text:'Foreign (UK)', value:'Foreign (UK)'},
        {text:'Foreign (US)', value:'Foreign (US)'},
        {text:'Foundation', value:'Foundation'},
        {text:'Government', value:'Government'},
        {text:'Institution', value:'Institution'},
        {text:'Non-market', value:'Non-market'},
        {text:'Private', value:'Private'},
        {text:'- All ContactID -', value:null},
       
      ],
      // Add more  filter options - type
      filtertype:[
       
        {text:'1 prop', value:'1 prop'},
        {text:'2 prop', value:'2 prop'},
        {text:'active listing', value:'active listing'},
        {text:'bigboy target', value:'bigboy target'},
        {text:'black-list', value:'black-list'},
        {text:'black-list (competitor)', value:'black-list (competitor)'},
        {text:'black-list (inbound request)', value:'black-list (inbound request)'},
        {text:'black-list (property manager)', value:'black-list (property manager)'},
        {text:'Charitable trust', value:'Charitable trust'},
        {text:'City owned', value:'City owned'},
        {text:'Co-Op:Common Mailing Address', value:'Co-Op:Common Mailing Address'},
        {text:'Gocart target area', value:'Gocart target area'},
        {text:'Government/Institution', value:'Government/Institution'},
        {text:'Granville Island', value:'Granville Island'},
        {text:'just sold', value:'just sold'},
        {text:'Leasehold', value:'Leasehold'},
        {text:'Non-profit', value:'Non-profit'},
        {text:'realtor own', value:'realtor own'},
        {text:'retail', value:'retail'},
        {text:'SRO', value:'SRO'},
        {text:'update pending', value:'update pending'},
        {text:'- All Type -', value:null},
       
      ],
      filterrlts:[
        
        {text:'1 (hang-out)', value:'1 (hang-out)'},
        {text:'2 (professional)', value:'2 (professional)'},
        {text:'3 (coffee)', value:'3 (coffee)'},
        {text:'4 (acquaintance)', value:'4 (acquaintance)'},
        {text:'5 (phone call)', value:'5 (phone call)'},
        {text:'- All Relationship -', value:null},
       
        
      ],
      filterivstrng:[
        
        {text:'$1 - $1.5 mil', value:'$1 - $1.5 mil'},
        {text:'$1.5 - $2 mil', value:'$1.5 - $2 milr'},
        {text:'$2 - $3 mil', value:'$2 - $3 mil'},
        {text:'$3 - $4 mil', value:'$3 - $4 mil'},
        {text:'$4 - $6 mil', value:'$4 - $6 mil'},
        {text:'$6 - $8 mil', value:'$6 - $8 mil'},
        {text:'$8 - $10 mil', value:'$8 - $10 mil'},
        {text:'over $10 mil', value:'over $10 mil'}, 
        {text:'- All Investment Range -', value:null},
       
      ],
      filtermarkposi:[

        {text:'4 (looking to buy)', value:'4 (looking to buy)'},
        {text:'5 (hold)', value:'5 (hold)'},
        {text:'6 (consider)', value:'6 (consider)'},
        {text:'7 (see offer)', value:'7 (see offer)'},
        {text:'HKL (n)', value:'HKL (n)'},
        {text:'HKL (sold)', value:'HKL (sold)'},
        {text:'HKL (y)', value:'HKL (y)'},
        {text:'HKL19', value:'HKL19'},
        {text:'possible', value:'possible'},
        {text:'update owner', value:'update owner'},
        {text:'- All Market Position -', value:null},
       
      ],
      filterlstrst:[
       
        {text:'Ass:hc', value:'Ass:hc'},
        {text:'Ass:hc2', value:'Ass:hc2'},
        {text:'hotel', value:'hotel'},
        {text:'industrial', value:'industrial'},
        {text:'land', value:'land'},
        {text:'multi-family/retail', value:'multi-family/retail'},
        {text:'office', value:'office'},
        {text:'retail', value:'retail'},
        {text:'- All Last Result -', value:null},
       
      ],
      filternxtact:[
        
        {text:'Accumulating', value:'Accumulating'},
        {text:'bc online check', value:'bc online check'},
        {text:'Big Portfolio', value:'Big Portfolio'},
        {text:'company search', value:'company search'},
        {text:'JS-dts-dt', value:'JS-dts-dt'},
        {text:'JS-wv', value:'JS-wv'},
        {text:'myLTS check', value:'myLTS check'},
        {text:'title search', value:'title search'},
        {text:'- All Next Action -', value:null},
       
      ],
      filtertags: ['All', 'Hello', 'Condo'],
      
      selectedcontactid : null,
      selectedcontactidvisible : null,
      selectedtype : null,
      selectedtypevisible : null,
      selectedrltn : null,
      selectedrltnvisible : null,
      selectedmarkposi : null,
      selectedmarkposivisible : false,
      selectedivstrng : null,
      selectedivstrngvisible : null,
      selectedlstrst: null,
      selectedlstrstvisible : null,
      selectednxtact: null,
      selectednxtactvisible : null,
      // Add more filter options - contact
      optioncontactid:[
        
        {id:1, text:'Developer', value:'Developer'},
        {id:2, text:'Foreign', value:'Foreign'},
        {id:3, text:'Foreign (HK)', value:'Foreign (HK)'},
        {id:4, text:'Foreign (CN)', value:'Foreign (CN)'},
        {id:5, text:'Foreign (KO)', value:'Foreign (KO)'},
        {id:6, text:'Foreign (SG)', value:'Foreign (SG)'},
        {id:7, text:'Foreign (TW)', value:'Foreign (TW)'}, 
        {id:8, text:'Foreign (UK)', value:'Foreign (UK)'},
        {id:9, text:'Foreign (US)', value:'Foreign (US)'},
        {id:10, text:'Foundation', value:'Foundation'},
        {id:11, text:'Government', value:'Government'},
        {id:12, text:'Institution', value:'Institution'},
        {id:13, text:'Non-market', value:'Non-market'},
        {id:14, text:'Private', value:'Private'},
        {id:15, text:'', value:''},
        {id:0, text:'-- Select ContactID --', value:null},
      ],
      // Add more  filter options - type
      optiontype:[
        
        {id:1, text:'1 prop', value:'1 prop'},
        {id:2, text:'2 prop', value:'2 prop'},
        {id:3, text:'active listing', value:'active listing'},
        {id:4, text:'bigboy target', value:'bigboy target'},
        {id:5, text:'black-list', value:'black-list'},
        {id:6, text:'black-list (competitor)', value:'black-list (competitor)'},
        {id:7, text:'black-list (inbound request)', value:'black-list (inbound request)'},
        {id:8, text:'black-list (property manager)', value:'black-list (property manager)'},
        {id:9, text:'Charitable trust', value:'Charitable trust'},
        {id:10, text:'City owned', value:'City owned'},
        {id:11, text:'Co-Op:Common Mailing Address', value:'Co-Op:Common Mailing Address'},
        {id:12, text:'Gocart target area', value:'Gocart target area'},
        {id:13, text:'Government/Institution', value:'Government/Institution'},
        {id:14, text:'Granville Island', value:'Granville Island'},
        {id:15, text:'just sold', value:'just sold'},
        {id:16, text:'Leasehold', value:'Leasehold'},
        {id:17, text:'Non-profit', value:'Non-profit'},
        {id:18, text:'realtor own', value:'realtor own'},
        {id:19, text:'retail', value:'retail'},
        {id:20, text:'SRO', value:'SRO'},
        {id:21, text:'update pending', value:'update pending'},
        {id:22, text:'', value:''},
        {id:0, text:'-- Select Type --', value:null},
       
      ],
      optionrltn:[
        
        {id:1, text:'1 (hang-out)', value:'1 (hang-out)'},
        {id:2, text:'2 (professional)', value:'2 (professional)'},
        {id:3, text:'3 (coffee)', value:'3 (coffee)'},
        {id:4, text:'4 (acquaintance)', value:'4 (acquaintance)'},
        {id:5, text:'5 (phone call)', value:'5 (phone call)'},
        {id:6, text:'', value:''},
        {id:0, text:'-- Select Relationship --', value:null},
       
        
      ],
      optionivstrng:[
       
        {id:1, text:'$1 - $1.5 mil', value:'$1 - $1.5 mil'},
        {id:2, text:'$1.5 - $2 mil', value:'$1.5 - $2 milr'},
        {id:3, text:'$2 - $3 mil', value:'$2 - $3 mil'},
        {id:4, text:'$3 - $4 mil', value:'$3 - $4 mil'},
        {id:5, text:'$4 - $6 mil', value:'$4 - $6 mil'},
        {id:6, text:'$6 - $8 mil', value:'$6 - $8 mil'},
        {id:7, text:'$8 - $10 mil', value:'$8 - $10 mil'},
        {id:8, text:'over $10 mil', value:'over $10 mil'}, 
        {id:9, text:'', value:''},
        {id:0, text:'-- Select Investment Range --', value:null},
       
      ],
      optionmarkposi:[
        {id:1, text:'4 (looking to buy)', value:'4 (looking to buy)'},
        {id:2, text:'5 (hold)', value:'5 (hold)'},
        {id:3, text:'6 (consider)', value:'6 (consider)'},
        {id:4, text:'7 (see offer)', value:'7 (see offer)'},
        {id:5, text:'HKL (n)', value:'HKL (n)'},
        {id:6, text:'HKL (sold)', value:'HKL (sold)'},
        {id:7, text:'HKL (y)', value:'HKL (y)'},
        {id:8, text:'HKL19', value:'HKL19'},
        {id:9, text:'possible', value:'possible'},
        {id:10, text:'update owner', value:'update owner'},
        {id:11, text:'', value:''},
        {id:0, text:'-- Select Market Position --', value:null},
       
      ],
      optionlstrst:[
       
        {id:1, text:'Ass:hc', value:'Ass:hc'},
        {id:2, text:'Ass:hc2', value:'Ass:hc2'},
        {id:3, text:'hotel', value:'hotel'},
        {id:4, text:'industrial', value:'industrial'},
        {id:5, text:'land', value:'land'},
        {id:6, text:'multi-family/retail', value:'multi-family/retail'},
        {id:7, text:'office', value:'office'},
        {id:8, text:'retail', value:'retail'},
        {id:9, text:'', value:''},
        {id:0, text:'-- Select Last Result --', value:null},
       
      ],
      optionnxtact:[
        
        {id:1, text:'Accumulating', value:'Accumulating'},
        {id:2, text:'bc online check', value:'bc online check'},
        {id:3, text:'Big Portfolio', value:'Big Portfolio'},
        {id:4, text:'company search', value:'company search'},
        {id:5, text:'JS-dts-dt', value:'JS-dts-dt'},
        {id:6, text:'JS-wv', value:'JS-wv'},
        {id:7, text:'myLTS check', value:'myLTS check'},
        {id:8, text:'title search', value:'title search'},
        {id:9, text:'', value:''},
        {id:0, text:'-- Select Next Action --', value:null},
       
      ],
      
      editcontactinfo:{
         database: null,
         id: null,
         data: {
          Title1: null,
          LastName1: null,
          FirstName1: null,
          Title2: null,
          LastName2: null,
          FirstName2: null,
          Company: null,
          Email: null,
          Telephone1: null,
          Telephone2: null,
          Telephone3: null,
          Telephone4: null,
          Telephone5: null,
          UserWhoUpdated: null
         },
      },
      editcontactaddress:{
         database: null,
         id: null,
         data: {
          Street1: null, 
          Street2: null,
          Street3: null,
          City: null,
          State: null,
          Zip: null,
          Country: null,
          UserWhoUpdated: null
         }
      },
      editadditionalinfo:{
         database: null,
         id: null,
         data: {
          ContactID: null,
          MarkPosi: null, // markposi
          Rltnshp: null, // rltnshp
          IvstRng: null, // ivstrng
          Type: null, //  type
          LastResult: null, // lastrslt
          NextAction: null,  // nextact
          UserWhoUpdated: null
         }
      },
      preveditcontactinfo:'',
      preveditcontactaddress:'',
      preveditadditionalinfo: '',
      carouselIndex: 0,
      
      items: [
        'Contact',
        'Address',
        'Additional Info'
 
      ],
      dots: {
        textAlign: 'center',
        fontSize: '30px',
        color: '#fff',
        position: 'absolute',
        bottom: '40px',
        left: 0,
        right: 0
      },

    }
  },
  mounted() {
    fb.auth.onAuthStateChanged(user =>{
    if(user){
      this.currentUser=user;
      var strDateTime = this.currentUser.metadata.lastSignInTime;
      var myDate = new Date(strDateTime);
      this.lastLogin= myDate.toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
    }
    })
  },
  watch:{  
    selectedcontactid: function(value){
      if (value == '')
       this.selectedcontactidvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optioncontactid')){
        this.updateDynamicOption(value, 'this.optioncontactid');
      }

    },
     selectedtype: function(value){
      if (value == '')
       this.selectedtypevisible = true;

      if (!this.findItemWith(value, 'value', 'this.optiontype')){
        this.updateDynamicOption(value, 'this.optiontype');
      }

    },
    selectedrltn: function(value){
      if (value == '')
       this.selectedrltnvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionrltn')){
        this.updateDynamicOption(value, 'this.optionrltn');
      }

    },
    selectedivstrng: function(value){
      if (value == '')
       this.selectedivstrngvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionivstrng')){
        this.updateDynamicOption(value, 'this.optionivstrng');
      }

    },
    selectedmarkposi: function(value){
      if (value == '')
       this.selectedmarkposivisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionmarkposi')){
        this.updateDynamicOption(value, 'this.optionmarkposi');
      }

    },
    selectedlstrst: function(value){
      if (value == '')
       this.selectedlstrstvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionlstrst')){
        this.updateDynamicOption(value, 'this.optionlstrst');
      }

    },
    selectednxtact: function(value){
      if (value == '')
       this.selectednxtactvisible = true;

      if (!this.findItemWith(value, 'value', 'this.optionnxtact')){
        this.updateDynamicOption(value, 'this.optionnxtact');
      }

    }, 
  },
  methods: {
    clearkeyword(){ 
       this.instantsearchtext = null;
       this.propertylist = [];
       this.showRecord = false;
       this.selectedfiltercontactid = null;
       this.selectedfiltertype = null;
       this.selectedfilterrlts = null;
       this.selectedfiltermarkposi = null;
       this.selectedfilterivstrng = null;
       this.selectedfilterlstrst= null;
       this.selectedfilternxtact= null;
      
    },
    loadproperty(){
      if ((this.instantsearchtext.length == 3) || (this.instantsearchtext.length >3 && this.propertylist.length==0))
      {
 
        this.searchtext = this.instantsearchtext;
        this.searchcriteria = 'name,company,telephone,contact-address,property-address,city,zip';
 
      this.showProgress = true;
      //https://index.laurel.workers.dev/?https://hv3ziiajak.execute-api.us-west-2.amazonaws.com/Beta/contact/wong//
      //https://us-central1-terratonepull-c669d.cloudfunctions.net/contacts/linkproperty/search?text=wong&criteria=name,company
      //'name', 'company','telephone','contactaddress','propertyaddress', 'city', 'postalcode'
  console.log('apipath='+this.$apipath);

      Vue.axios.get(this.$apipath + 'contacts/linkproperty/search?text='+this.instantsearchtext+'&criteria='+this.searchcriteria)
        .then((resp)=>{
        //  console.log("text="+this.instantsearchtext);
        //  console.warn(resp.data);
        
          this.propertylist=resp.data;
          
          if (this.propertylist.length == 0)
          {
            this.showRecord = false;    
          }
          else
            this.showRecord = true;
           // this.propertyitems = Object.values(this.filterPropertyList);      
            this.showProgress = false;
           })
      }
    }, 
    showModal(id, database, index) {

      this.carouselIndex = 0;
 
      index = this.propertylist.findIndex(item => (item.id === id) && (item.database === database));

      this.selectedcontactid = this.propertylist[index].ContactID;
      this.selectedmarkposi = this.propertylist[index].MarkPosi;
      this.selectedrltn = this.propertylist[index].Rltnshp;
      this.selectedivstrng = this.propertylist[index].IvstRng;
      this.selectedtype = this.propertylist[index].Type;
      this.selectedlstrst = this.propertylist[index].LastResult;
      this.selectednxtact = this.propertylist[index].NextAction;
      
      this.editcontactinfo = {
         database: this.propertylist[index].database,
         id:  this.propertylist[index].id,
         data: {
          Title1: this.propertylist[index].Title1,
          LastName1:  this.propertylist[index].LastName1,
          FirstName1:  this.propertylist[index].FirstName1,
          Title2:  this.propertylist[index].Title2,
          LastName2: this.propertylist[index].LastName2,
          FirstName2: this.propertylist[index].FirstName2,
          Company: this.propertylist[index].Company,    
          Email: this.propertylist[index].Email,
          Telephone1: this.propertylist[index].Telephone1,
          Telephone2: this.propertylist[index].Telephone2,
          Telephone3: this.propertylist[index].Telephone3,
          Telephone4: this.propertylist[index].Telephone4,
          Telephone5: this.propertylist[index].Telephone5,
          UserWhoUpdated: this.currentUser.displayName,
         }
      };
      this.editcontactaddress = {
         database: this.propertylist[index].database,
         id: this.propertylist[index].id,
         data: {
          Street1: this.propertylist[index].Street1,
          Street2: this.propertylist[index].Street2,
          Street3: this.propertylist[index].Street3,
          City: this.propertylist[index].City,
          State: this.propertylist[index].State,
          Zip: this.propertylist[index].Zip,
          Country: this.propertylist[index].Country, 
          
          UserWhoUpdated: this.currentUser.displayName,
         }
      };
      this.editadditionalinfo = {
         database: this.propertylist[index].database,
         id: this.propertylist[index].id,
         data: {
          ContactID: this.propertylist[index].ContactID,
          MarkPosi: this.propertylist[index].MarkPosi, // Key1
          Rltnshp: this.propertylist[index].Rltnshp,  // Key2
          IvstRng: this.propertylist[index].IvstRng, // Key 4
          Type: this.propertylist[index].Type, // Key5
          LastResult: this.propertylist[index].LastResult, 
          NextAction: this.propertylist[index].NextAction,
          UserWhoUpdated: this.currentUser.displayName,
         }
      };
      this.preveditcontactinfo = JSON.stringify(this.editcontactinfo);
      this.preveditcontactaddress = JSON.stringify(this.editcontactaddress);
      this.preveditadditionalinfo = JSON.stringify(this.editadditionalinfo)
      this.modalVisible = true;
      
    },
    savecontact(ons, id, database){
      
      this.editadditionalinfo.data.ContactID = this.selectedcontactid;
      this.editadditionalinfo.data.MarkPosi = this.selectedmarkposi;
      this.editadditionalinfo.data.Rltnshp = this.selectedrltn;
      this.editadditionalinfo.data.IvstRng = this.selectedivstrng;
      this.editadditionalinfo.data.Type = this.selectedtype;
      this.editadditionalinfo.data.LastResult = this.selectedlstrst;
      this.editadditionalinfo.data.NextAction = this.selectednxtact;

      if ((this.preveditcontactinfo != JSON.stringify(this.editcontactinfo)) ||
        (this.preveditcontactaddress != JSON.stringify(this.editcontactaddress)) ||
        (this.preveditadditionalinfo != JSON.stringify(this.editadditionalinfo))) {
            
                ons.notification.confirm('Do you want to save the change?').then((response) =>
                {
                    if (response==1) // yes
                    {
                      if (!this.editcontactinfo.data.FirstName1 & !this.editcontactinfo.data.LastName1  &
                        !this.editcontactinfo.data.FirstName2 & !this.editcontactinfo.data.LastName2 &
                        !this.editcontactinfo.data.Company){
                            ons.notification.alert('Contact Name / Company cannot be empty.');
                            this.carouselIndex = 0;
                            
                      }
                      else{
                        this.savecontactinfo(id, database);
                        this.savecontactaddress(id, database);
                        this.saveadditionalinfo(id, database);
                         this.modalVisible = false;
                      }
                    }
                    else
                      this.modalVisible = false;
                    
                });
             
        }
        else
          this.modalVisible = false;
    },
    savecontactinfo(id, database){
     
     try {
          const headers = {
              'Content-Type': 'application/json' 
          };

          Vue.axios.put(this.$apipath + 'contacts/',  JSON.stringify(this.editcontactinfo), {headers: headers})
            .then((resp)=>{
               
              console.warn(resp.data);
              if (resp.status == 200)
              {
                 
                // Refresh filterpropertylist
                  let index = this.filterPropertyList.findIndex(item => (item.id === id) && (item.database === database));

                  if (index >= 0){
                    this.filterPropertyList[index].Title1 = this.editcontactinfo.data.Title1;
                    this.filterPropertyList[index].LastName1 = this.editcontactinfo.data.LastName1;
                    this.filterPropertyList[index].FirstName1 = this.editcontactinfo.data.FirstName1;
                    this.filterPropertyList[index].Title2 = this.editcontactinfo.data.Title2;
                    this.filterPropertyList[index].LastName2 = this.editcontactinfo.data.LastName2;
                    this.filterPropertyList[index].FirstName2 = this.editcontactinfo.data.FirstName2;
                    this.filterPropertyList[index].Company = this.editcontactinfo.data.Company;
                    this.filterPropertyList[index].Email = this.editcontactinfo.data.Email;
                    this.filterPropertyList[index].Telephone1 = this.editcontactinfo.data.Telephone1;
                    this.filterPropertyList[index].Telephone2 = this.editcontactinfo.data.Telephone2;
                    this.filterPropertyList[index].Telephone3 = this.editcontactinfo.data.Telephone3;
                    this.filterPropertyList[index].Telephone4 = this.editcontactinfo.data.Telephone4;
                    this.filterPropertyList[index].Telephone5 = this.editcontactinfo.data.Telephone5;
                  }
                 
                 this.modalVisible=false;
                 this.savedtoastVisible=true;
              }
              else
              {
                 this.error = resp.status + resp.statusText;
                 this.errortoastVisible = true;
                 
              }     
              
            })
      } catch (error)
      {
         let errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      }      
      
    },
    savecontactaddress(id, database){
      //// in here, Call SaveContact API with editcontactaddress object
      
       try {
          const headers = {
              'Content-Type': 'application/json' 
          };

          Vue.axios.put(this.$apipath + 'contacts/',  JSON.stringify(this.editcontactaddress), {headers: headers})
            .then((resp)=>{
             
              if (resp.status == 200)
              {
                 
                // Refresh filterpropertylist
                  let index = this.filterPropertyList.findIndex(item => (item.id === id) && (item.database === database));
                  
                  if (index >= 0){
                    this.filterPropertyList[index].Street1 = this.editcontactaddress.data.Street1;
                    this.filterPropertyList[index].Street2 = this.editcontactaddress.data.Street2;
                    this.filterPropertyList[index].Street3 = this.editcontactaddress.data.Street3;
                    this.filterPropertyList[index].City = this.editcontactaddress.data.City;
                    this.filterPropertyList[index].State = this.editcontactaddress.data.State;
                    this.filterPropertyList[index].Zip = this.editcontactaddress.data.Zip;
                    this.filterPropertyList[index].Country = this.editcontactaddress.data.Country; 

                  }
                 
                 this.modalVisible=false;
                 this.savedtoastVisible=true;
              }
              else
              {
                 this.error = resp.status + resp.statusText;
                 this.errortoastVisible = true;
                 
              }
 
             
            })
      } catch (error)
      {
         let errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      }
       
    },
    saveadditionalinfo(id, database){   
      //// in here, Call SaveContact API with editadditional object
      
      /*this.editadditionalinfo.data.ContactID = this.selectedcontactid;
      this.editadditionalinfo.data.MarkPosi = this.selectedmarkposi;
      this.editadditionalinfo.data.Rltnshp = this.selectedrltn;
      this.editadditionalinfo.data.IvstRng = this.selectedivstrng;
      this.editadditionalinfo.data.Type = this.selectedtype;
      this.editadditionalinfo.data.LastResult = this.selectedlstrst;
      this.editadditionalinfo.data.NextAction = this.selectednxtact; */
      
      try {
          const headers = {
              'Content-Type': 'application/json' 
          };

          Vue.axios.put(this.$apipath + 'contacts/',  JSON.stringify(this.editadditionalinfo), {headers: headers})
            .then((resp)=>{
             
              if (resp.status == 200)
              {   
                // Refresh filterpropertylist
                  let index= this.filterPropertyList.findIndex(item => (item.id === id) && (item.database === database));
                  
                  if (index >= 0){
                    this.filterPropertyList[index].ContactID = this.editadditionalinfo.data.ContactID;
                    this.filterPropertyList[index].MarkPosi = this.editadditionalinfo.data.MarkPosi;
                    this.filterPropertyList[index].Rltnshp = this.editadditionalinfo.data.Rltnshp;
                    this.filterPropertyList[index].IvstRng = this.editadditionalinfo.data.IvstRng;
                    this.filterPropertyList[index].Type = this.editadditionalinfo.data.Type;
                    this.filterPropertyList[index].LastResult = this.editadditionalinfo.data.LastResult;
                    this.filterPropertyList[index].NextAction = this.editadditionalinfo.data.NextAction; 
                  }
                 
                 this.modalVisible=false;
                 this.savedtoastVisible=true;
              }
              else
              {
                 this.error = resp.status + resp.statusText;
                 this.errortoastVisible = true;
                 
              }
                
              
            })
      } catch (error)
      {
         let errorObject=JSON.parse(JSON.stringify(error));
        console.log(errorObject);
      }
    },
    askforLink(ons, database, contactid){       
      ons.notification.confirm('Do you want to link a property?').then((response) =>
      {
          if (response==1) // yes
          {
              this.newdatabase = database;
              this.olddatabase = database;
              this.newcontactid = contactid;
              this.linkpropertykeyword = null;
              this.linkpropertypid = null;
              this.linkpropertylist = null;
              this.newlinkdialogVisible = true;
          }
          
      });
    },
    selectNewlink(ons, taxrecord_id, LocationOriginal, LocationCity, LocationState, LocationZip, LocationCountry, PID, MorePIDs, oldcontactid){  
      ons.notification.confirm('Confirm link property '+  LocationOriginal + '(' + PID + ') ' + (MorePIDs??'') +'?').then((response) =>
      {
         if (response==1) // yes
         {
           // for undo action
            this.oldcontactid = oldcontactid;
            this.newtaxrecordsid = taxrecord_id;

             try{
                        const headers = {
                              'Content-Type': 'application/json' 
                          };
                        let ids = [];
                        ids.push(taxrecord_id);

                        let newlink = {
                          database : this.newdatabase,
                          contact_id : this.newcontactid,
                          taxrecord_ids: ids
                        };
                       
                       //console.log('newlink'+JSON.stringify(newlink));
                        Vue.axios.put(this.$apipath + 'contacts/linkproperty/relink/', JSON.stringify(newlink), {
                          headers: headers})
                            .then((resp)=>{
                               
                              if (resp.status == 200)
                              {  
                                  let index=-1;

                                   // Remove old list
 
                                  let pindex=-1; 
                                  console.log('oldcontactid'+this.oldcontactid);

                                  if (this.oldcontactid != null)
                                  {
                                      index = this.filterPropertyList.findIndex(item => (item.id === this.oldcontactid) && (item.database === this.newdatabase));

                                      if (index >= 0)
                                      {
                                        pindex = this.filterPropertyList[index].PropertyList.findIndex(pitem => pitem.taxrecord_id === taxrecord_id);

                                        console.log('index pindex'+index + ' ' + pindex);
                                        this.relinkLocationOriginal = '';
                                        this.relinkLocationCity= '';
                                        this.relinkLocationState= '';
                                        this.relinkLocationZip= '';
                                        this.relinkLocationCountry= '';
                                        this.relinkPID= '';
                                        this.relinkMorePIDs= '';

                                        if ((pindex >= 0)){
        
                                            this.filterPropertyList[index].PropertyList.splice(pindex, 1);
                                            console.log('remove old list'+JSON.stringify(this.filterPropertyList[index].PropertyList) + ' pindex' + pindex);
                                        }
                                      }
                                  }

                                  // Add new property

                                  index = this.filterPropertyList.findIndex(item => (item.id === newlink.contact_id) && (item.database === newlink.database));
                                   
                                  if (index >= 0){
                                    let newproperty = {
                                        taxrecord_id:taxrecord_id,
                                        LocationOriginal:LocationOriginal,
                                        LocationCity:LocationCity,
                                        LocationState:LocationState,
                                        LocationZip:LocationZip,
                                        LocationCountry:LocationCountry,
                                        PID:PID,
                                        MorePIDs:MorePIDs,
                                        contact_id: newlink.contact_id
                                      };

                                      this.filterPropertyList[index].PropertyList.push(newproperty);
                                      console.log('add new list'+JSON.stringify(newproperty));
                                        //this.filterPropertyList[index].ContactID = this.editadditionalinfo.data.ContactID;
                                  }

                                this.newlinkdialogVisible = false;
                                this.newlinkedtoastVisible = true;
                              }
                              else
                              {
                                this.error = resp.status + resp.statusText;
                                this.errortoastVisible = true;
                                
                              }
                            })
                            
                            
                  }
                  catch (error){
                      let errorObject=JSON.parse(JSON.stringify(error));
                        console.log(errorObject);
                
                  }
              

           
         }
         
      });
    },
    askforRelink(ons,oldid, database, taxrecord_id, LocationOriginal, PID, MorePIDs){
      ons.notification.confirm('Do you want to relink the property? '+ (LocationOriginal??'') + ' ('+ (PID??'')+')' + (MorePIDs??'')).then((response) =>
      {
         if (response==1) // yes
         {
             this.newdatabase = database;
             this.olddatabase = database;
             this.newtaxrecordsid = taxrecord_id;
             this.oldcontactid = oldid;
             this.linkcontactkeyword = null;
             this.linkcontactlist = null;
             this.linkdialogVisible = true;
         }
         
      });

    },
    selectRelink(ons, newid, database, newcontactname, newcontactcompany, undo){
      
      ons.notification.confirm('Confirm Relink to '+ (newcontactname ?? newcontactcompany) +'?').then((response) =>
      {
         if (response==1) // yes
         {
            // for undo action
            if (!undo)
            {
             this.newcontactid = newid;
              
            }

            this.actionRelink(newid, database, false);
         }
         
      });
    },
    actionRelink(newid, database, undo){
      try{
            const headers = {
                  'Content-Type': 'application/json' 
              };
            let ids = [];
            ids.push(this.newtaxrecordsid);

            let relink = {
              database : database,
              contact_id : newid,
              taxrecord_ids: ids
            };
             
            Vue.axios.put(this.$apipath + 'contacts/linkproperty/relink/', JSON.stringify(relink), {
              headers: headers})
                .then((resp)=>{
                   
         
                  if (resp.status == 200) {  

                    if (!undo){
                      // Remove old list
                      let index=-1;
                      let pindex=-1; 
                      index = this.filterPropertyList.findIndex(item => (item.id === this.oldcontactid) && (item.database === database));

                      if (index >= 0) {
                          pindex = this.filterPropertyList[index].PropertyList.findIndex(pitem => pitem.taxrecord_id === this.newtaxrecordsid);
                          this.relinkLocationOriginal = '';
                          this.relinkLocationCity= '';
                          this.relinkLocationState= '';
                          this.relinkLocationZip= '';
                          this.relinkLocationCountry= '';
                          this.relinkPID= '';
                          this.relinkMorePIDs= '';

                          if (pindex >= 0) {
                              this.relinkLocationOriginal = this.filterPropertyList[index].PropertyList[pindex].LocationOriginal;
                              this.relinkLocationCity= this.filterPropertyList[index].PropertyList[pindex].LocationCity;
                              this.relinkLocationState= this.filterPropertyList[index].PropertyList[pindex].LocationState;
                              this.relinkLocationZip= this.filterPropertyList[index].PropertyList[pindex].LocationZip;
                              this.relinkLocationCountry= this.filterPropertyList[index].PropertyList[pindex].LocationCountry;
                              this.relinkPID= this.filterPropertyList[index].PropertyList[pindex].PID;
                              this.relinkMorePIDs= this.filterPropertyList[index].PropertyList[pindex].MorePIDs;
                              this.filterPropertyList[index].PropertyList.splice(pindex, 1);
                          }
                      }
                      // add to new list
                      index = this.filterPropertyList.findIndex(item => (item.id === newid) && (item.database === database));

                      if (index >= 0){
                        let newproperty = {
                            taxrecord_id:this.newtaxrecordsid,
                            LocationOriginal:this.relinkLocationOriginal,
                            LocationCity:this.relinkLocationCity,
                            LocationState:this.relinkLocationState,
                            LocationZip:this.relinkLocationZip,
                            LocationCountry:this.relinkLocationCountry,
                            PID:this.relinkPID,
                            MorePIDs:this.relinkMorePIDs,
                            contact_id: newid
                          };
                         this.filterPropertyList[index].PropertyList.push(newproperty);
                         
                      }

                      this.linkdialogVisible = false;
                      this.relinkedtoastVisible = true;
                    }
                    else { // undo
                       // Remove old list
                        let index=-1;
                        let pindex=-1;

                      if (this.newcontactid != null) {
                        index = this.filterPropertyList.findIndex(item => (item.id === this.newcontactid) && (item.database === database));
                         
                        if (index >= 0) {
                            pindex = this.filterPropertyList[index].PropertyList.findIndex(pitem => pitem.taxrecord_id === this.newtaxrecordsid);
                            
                            this.relinkLocationOriginal = '';
                            this.relinkLocationCity= '';
                            this.relinkLocationState= '';
                            this.relinkLocationZip= '';
                            this.relinkLocationCountry= '';
                            this.relinkPID= '';
                            this.relinkMorePIDs= '';

                            if (pindex >= 0) {
                                this.relinkLocationOriginal = this.filterPropertyList[index].PropertyList[pindex].LocationOriginal;
                                this.relinkLocationCity= this.filterPropertyList[index].PropertyList[pindex].LocationCity;
                                this.relinkLocationState= this.filterPropertyList[index].PropertyList[pindex].LocationState;
                                this.relinkLocationZip= this.filterPropertyList[index].PropertyList[pindex].LocationZip;
                                this.relinkLocationCountry= this.filterPropertyList[index].PropertyList[pindex].LocationCountry;
                                this.relinkPID= this.filterPropertyList[index].PropertyList[pindex].PID;
                                this.relinkMorePIDs= this.filterPropertyList[index].PropertyList[pindex].MorePIDs;
                                this.filterPropertyList[index].PropertyList.splice(pindex, 1);
                            }
                        }
                      }

                      // add to new list
                      index = this.filterPropertyList.findIndex(item => (item.id === newid) && (item.database === database));

                      if (index >= 0){
                        let newproperty = {
                            taxrecord_id:this.oldtaxrecordsid,
                            LocationOriginal:this.relinkLocationOriginal,
                            LocationCity:this.relinkLocationCity,
                            LocationState:this.relinkLocationState,
                            LocationZip:this.relinkLocationZip,
                            LocationCountry:this.relinkLocationCountry,
                            PID:this.relinkPID,
                            MorePIDs:this.relinkMorePIDs,
                            contact_id: newid
                          };
                         this.filterPropertyList[index].PropertyList.push(newproperty);

                      }

                    }
                  }
                  else
                  {
                    this.error = resp.status + resp.statusText;
                    this.errortoastVisible = true;
                    
                  }
                })

      }
      catch (error){
          let errorObject=JSON.parse(JSON.stringify(error));
          this.error = errorObject;
          this.errortoastVisible = true; 
      }


    },
    askforUnlink(ons,id, database, taxrecord_id, LocationOriginal,  LocationCity, LocationState, LocationZip, LocationCountry,PID, MorePIDs){
     ons.notification.confirm('Are you sure to UNLINK the property? '+ (LocationOriginal??'') + ' ('+ (PID??'')+')' + (MorePIDs??'')).then((response) =>
     {
              if (response==1) {
                 // for undo action
                this.newcontactid = null;
                this.newdatabase = database; 
                this.newtaxrecordsid = taxrecord_id;
                this.olddatabase = database;
                this.oldcontactid = id;
                this.oldtaxrecordsid= taxrecord_id;

                this.relinkLocationOriginal = LocationOriginal;
                this.relinkLocationCity = LocationCity;
                this.relinkLocationState =  LocationState;
                this.relinkLocationZip = LocationZip;
                this.relinkLocationCountry = LocationCountry;
                this.relinkPID = PID;
                this.relinkMorePIDs = MorePIDs;

                try{
                        const headers = {
                              'Content-Type': 'application/json' 
                          };
                        let ids = [];
                        ids.push(taxrecord_id);

                        let unlink = {
                          database : database,
                          contact_id : null,
                          taxrecord_ids: ids
                        };
                         
                        Vue.axios.put(this.$apipath + 'contacts/linkproperty/relink/', JSON.stringify(unlink), {
                          headers: headers})
                        .then((resp)=>{
                               
                              if (resp.status == 200) {  
                                  let index=-1;
                                  let pindex=-1;

                                  index = this.filterPropertyList.findIndex(item => (item.id === id) && (item.database === database));

                                  if (index >= 0){   
                                    pindex = this.filterPropertyList[index].PropertyList.findIndex(pitem => pitem.taxrecord_id === taxrecord_id);
                                  
                                    if (pindex >= 0){
                                        console.log('remove old list ' + JSON.stringify(this.filterPropertyList[index].PropertyList[pindex]) + 'pindex'+pindex);

                                          this.filterPropertyList[index].PropertyList.splice(pindex, 1);
                                          
                                          //this.filterPropertyList[index].ContactID = this.editadditionalinfo.data.ContactID;
                                    }
                                  }
 
                                this.unlinkedtoastVisible = true;
                              }
                              else
                              {
                                this.error = resp.status + resp.statusText;
                                this.errortoastVisible = true;
                                
                              }
                            })
  
                  }
                  catch (error){
                      let errorObject=JSON.parse(JSON.stringify(error));
                        console.log(errorObject);
                
                  }
               
              }
         
     });

    },
    undo(action){     
      if (action == 'newlink'){
          this.actionRelink(this.oldcontactid, this.olddatabase, true);
          this.newlinkedtoastVisible = false;
      }
      if (action == 'relink'){
          this.actionRelink(this.oldcontactid, this.olddatabase, true);
          this.relinkedtoastVisible = false;
      }
      if (action == 'unlink'){
          this.actionRelink(this.oldcontactid, this.olddatabase, true);
          this.unlinkedtoastVisible = false;
      }
    },
    loadlinkcontact(){
      if (this.linkcontactkeyword.length >2){
        let searchcriteria = 'name,company,telephone,contact-address,city,zip';
 
        Vue.axios.get(this.$apipath + 'contacts/linkproperty/search?text='+this.linkcontactkeyword+'&criteria='+searchcriteria+'&database='+this.newdatabase)
          .then((resp)=>{
            this.linkcontactlist = resp.data.slice(0, 3);
        
          })
      }
    },
    askforCopyto(ons,id, database, taxrecord_id, LocationOriginal,  LocationCity, LocationZip, LocationState, LocationCountry, ContactStreet, ContactCity, ContactZip){

      // console.log('contact'+ContactStreet+ContactCity+ContactZip);
      if ((!ContactStreet) & (!ContactCity) & (!ContactZip))
      {
          ons.notification.confirm('Are you sure to copy this property address to contact address? '+ (LocationOriginal??'') + ' '+ (LocationCity??'')+' ' + (LocationZip??'')+ ' '+ (LocationState??'')+ ' '+ (LocationCountry??'')).then((response) =>
          {
                    if (response==1) {
                           // console.log('copy....');

                            this.editcontactaddress = {
                              database: database,
                              id: id,
                              data: {
                                Street1: LocationOriginal,
                                City: LocationCity,
                                State: LocationState,
                                Zip: LocationZip,
                                Country: LocationCountry, 
                                
                                UserWhoUpdated: this.currentUser.displayName,
                              }
                          };
                       // console.warn('newaddress='+JSON.stringify(this.editcontactaddress));
                    try {
                            const headers = {
                                'Content-Type': 'application/json' 
                            };
                            
                            
                            Vue.axios.put(this.$apipath + 'contacts/',  JSON.stringify(this.editcontactaddress), {headers: headers})
                              .then((resp)=>{
                              
                                if (resp.status == 200)
                                {
                                  
                                  // Refresh filterpropertylist
                                    let index = this.filterPropertyList.findIndex(item => (item.id === id) && (item.database === database));
                                    
                                    if (index >= 0){
                                      this.filterPropertyList[index].Street1 = LocationOriginal;
                                      this.filterPropertyList[index].City = LocationCity;
                                      this.filterPropertyList[index].State = LocationState;
                                      this.filterPropertyList[index].Zip = LocationZip;
                                      this.filterPropertyList[index].Country = LocationCountry;

                                    }
                                   this.savedtoastVisible=true;
                                }
                                else
                                {
                                  this.error = resp.status + resp.statusText;
                                  this.errortoastVisible = true;
                                  
                                }
                  
                              
                              }
                              
                              ) 
                        } catch (error)
                        {
                          let errorObject=JSON.parse(JSON.stringify(error));
                          console.log(errorObject);
                        }
                    }
              
          });
      }
      else {
        ons.notification.alert('Contact address is not empty. Cannot copy from property.');
      }

    },
    loadlinkpropertybyaddress(){
      this.linkpropertypid = null;
      if (this.linkpropertykeyword.length >2) { 
        let apistr = 'text='+this.linkpropertykeyword + '&'+ 'database='+this.newdatabase + '&method=address';

        Vue.axios.get(this.$apipath + 'contacts/linkproperty/prop-search?'+apistr)
        .then((resp)=>{ 
          this.linkpropertylist=resp.data.slice(0, 3);      
        })
      }
    },  
    loadlinkpropertybypid(){
      this.linkpropertykeyword = null;
      if (this.linkpropertypid.length == 11) { 
        let apistr = 'text='+this.linkpropertypid + '&'+ 'database='+this.newdatabase + '&method=all-pid';

        Vue.axios.get(this.$apipath + 'contacts/linkproperty/prop-search?'+apistr)
        .then((resp)=>{ 
          this.linkpropertylist=resp.data.slice(0, 3);      
        })
      }
    },  
    filterContactID: function(propertylist){
       
      if (this.selectedfiltercontactid == null)
        return propertylist
      else
        return Object.values(propertylist).filter(item=> item.ContactID === this.selectedfiltercontactid)
    },
    filterType: function(propertylist){
      if (this.selectedfiltertype == null)
        return propertylist
      else
        return Object.values(propertylist).filter(item=> item.Type === this.selectedfiltertype)
    },
    filterRelationship: function(propertylist){
      if (this.selectedfilterrlts == null)
        return propertylist
      else
        return Object.values(propertylist).filter(item=> item.Rltnshp === this.selectedfilterrlts)
    },
    filterIvstrng: function(propertylist){
      if (this.selectedfilterivstrng == null)
        return propertylist
      else
        return Object.values(propertylist).filter(item=> item.IvstRng === this.selectedfilterivstrng)
    },
    filterMarkposi: function(propertylist){
      if (this.selectedfiltermarkposi == null)
        return propertylist
      else
        return Object.values(propertylist).filter(item=> item.MarkPosi=== this.selectedfiltermarkposi)
    },
    filterLstRst: function(propertylist){
      if (this.selectedfilterlstrst == null)
        return propertylist
      else
        return Object.values(propertylist).filter(item=> item.LastResult === this.selectedfilterlstrst)
    },
    filterNxtAct: function(propertylist){
      if (this.selectedfilternxtact == null)
        return propertylist
      else
        return Object.values(propertylist).filter(item=> item.NextAction === this.selectedfilternxtact)
    },
    filterInstant: function(propertylist){
      if (this.instantsearchtext == null || this.instantsearchtext == "")
        return propertylist
      else
        return Object.values(propertylist).filter(item=> 
        (item.Company != null && item.Company.toLowerCase().includes(this.instantsearchtext.toLowerCase()) ) ||
        (item.FirstName1 != null && item.FirstName1.concat(' ').concat(item.LastName1).toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.FirstName2 != null && item.FirstName2.concat(' ').concat(item.LastName2).toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.Telephone1 != null && item.Telephone1.toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.Telephone2 != null && item.Telephone2.toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.Telephone3 != null && item.Telephone3.toLowerCase().includes(this.instantsearchtext.toLowerCase()) ) ||
        (item.Telephone4 != null && item.Telephone4.toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.Telephone5 != null && item.Telephone5.toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.Street1 != null && item.Street1.toLowerCase().includes(this.instantsearchtext.toLowerCase()) ) ||
        (item.Street2 != null && item.Street2.toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.Street3 != null && item.Street3.toLowerCase().includes(this.instantsearchtext.toLowerCase())) ||
        (item.City != null && item.City.toLowerCase().includes(this.instantsearchtext.toLowerCase())  ) ||
        (item.Zip != null && item.Zip.toLowerCase().includes(this.instantsearchtext.toLowerCase())  )  ||
        (JSON.stringify(item.PropertyList ).toLowerCase().includes(this.instantsearchtext.toLowerCase()) )
        )   
    },
    findItemWith: function (value, prop, objstr) {
      for (var item in eval(objstr)) {     
      if (eval(objstr)[item][prop] === value) {
           return eval(objstr)[item];
        }
        }
      return false;
    },
    updateDynamicOption: function(value, objstr) {
      var item = this.findItemWith(true, 'dynamic', objstr);
      if (!item) {
         eval(objstr).push({
          id: eval(objstr).length,
          text: value, 
          value: value,
          dynamic: true
        });
       
      }
      item.text = value;
      item.value = value;
    },
  titleCase: function (phrase) {
      return phrase
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },  
  highlight(words, query) {
        if(query === '') { return words }
        if(words == null) { return words }

       var newwords, newhighlight;

       newwords = words.trim();
       
       var index = newwords.indexOf(query.trim());
       var len = query.length;
       var nextindex = index + len;

       if (index >= 0){
          newhighlight = newwords.substring(0, index) + '<span name="highlightext" class="nohighlight">' + newwords.substring(index, nextindex) + '</span>' + newwords.substring(nextindex, (newwords.length));
           
       }
       else
          newhighlight = newwords;
       
       return newhighlight;

    },
  highlightTitleCase(words, query) {
        if(query === '') { return words }
        if(words == null) { return words }
       var a = words.split(' '), i, newwords, newhighlight;
        newwords = '';

       for (i in a){
          newwords = newwords + ' ' + this.titleCase(a[i]);
       }
       newwords = newwords.trim();
       
       var index = newwords.toLowerCase().indexOf(query.trim().toLowerCase());
       var len = query.length;
       var nextindex = index + len;

       if (index >= 0){
          newhighlight = newwords.substring(0, index) + '<span name="highlightext" class="nohighlight">' + newwords.substring(index, nextindex) + '</span>' + newwords.substring(nextindex, (newwords.length));
            

       }
       else
          newhighlight = newwords;
       
       return newhighlight;

    },
  togglehighlight() { 
      var x = document.getElementsByName("highlightext");
      var i;
      if (this.toggleHL){
      for (i = 0; i < x.length; i++) {
         x[i].className ="nohighlight";}
      }else{
        for (i = 0; i < x.length; i++) {
         x[i].className ="highlight";}
      }
      this.toggleHL = !this.toggleHL;
    },
  openModal() { 
    
    this.$refs.addmodal.show();
    }

  },
  computed: {
      filterPropertyList: function(){
          return this.filterInstant(this.filterLstRst(this.filterNxtAct(this.filterContactID(this.filterType(this.filterRelationship(this.filterIvstrng(this.filterMarkposi(this.propertylist)))))))).slice(0, 50)
      }
  }
}

 
</script>


<style>
.highlight {
  background-color: rgb(241, 241, 100);
}
.nohighlight {
  background-color: none;
}
 
#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.relink {
  font-weight: bold;
  color: #2c3e50;
  font-size: 14px;
  text-decoration: underline;
}

.contactname {
  font-weight: bold;
}

.sidebar {
  margin: 20px 10px 20px 20px;
}
 
.companyicon {
  margin-top: 0.2em 0 0 0; 
  color: #FFF;
}

#inputtext {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px; 
    text-align: center;
}

.filtercontrol {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 100%;
}

.filters {
  width: 48%;
}

.filtermsg {
  padding: 0 10px 0 10px;

}

.editrow {
  width:96%;
}

.editmr {
 width:20%
}

.editname {
  width:40%
}

.edithalfrow {
  width:45%
}

.editoption {
  width:80%
}

.fulldiv {
  width:100%;
}

.fabtop {
  position: fixed;
  top: 80%;
  right: 0.3em;
  width:1.5em!important;
  height:1.5em!important;
  padding: 0 0 0 0!important; 
  background: #57a188;
  border:none;
  outline:none;
  color:#FFF;
  font-size:25px;
   
  transition:.3s;    
 

}

.fabadd {
  position: fixed;
  top: 60%;
  right: 0.3em;
  width:1.5em!important;
  height:1.5em!important;
  padding: 0 0 0 0!important; 
  background: #57a188;
  border:none;
  outline:none;
  color:#FFF;
  font-size:25px;
   
  transition:.3s;    

}

.list-item__expand-chevron{
  z-index: 0!important
}

.propertyowned {
  margin-left: 15px; 
  width: 100%;
  text-align: center;
  border-radius: 8px;
  font-weight: 500;
  color: white;
  background-color:rgb(77, 77, 133);  
}

.propertydetail {
  color: navy;
}
</style>