import Vue from 'vue'
import VueRouter from 'vue-router'
/*import Home from '../views/Home.vue' */
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue' 
import SearchDetail from '../views/SearchDetail.vue'
import AddDetail from '../views/AddDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'searchdetail',
    component: SearchDetail,
    meta: {
        protected: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
},
{
    path: '/logout',
    name: 'logout',
    component: Logout
},
{
  path: '/adddetail',
  name: 'adddetail',
  component: AddDetail,
  meta: {
    protected: true
}
},
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
        protected: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL, 
  routes
})

export default router
